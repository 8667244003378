define("discourse/plugins/discourse-fingerprint/discourse/components/modal/fingerprint-details", ["exports", "discourse/components/d-modal", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dModal, _i18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FingerprintDetails = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <DModal @title={{i18n "fingerprint.details"}} @closeModal={{@closeModal}}>
      <:body>
        <table class="fingerprint">
          <tbody>
            {{#each-in @model.data as |key value|}}
              <tr>
                <td class="key">{{key}}</td>
                <td class="value">{{value}}</td>
              </tr>
            {{/each-in}}
          </tbody>
        </table>
      </:body>
    </DModal>
  
  */
  {
    "id": "3eIhwxKh",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@title\",\"@closeModal\"],[[28,[32,1],[\"fingerprint.details\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n      \"],[10,\"table\"],[14,0,\"fingerprint\"],[12],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[30,2,[\"data\"]]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n              \"],[10,\"td\"],[14,0,\"key\"],[12],[1,[30,4]],[13],[1,\"\\n              \"],[10,\"td\"],[14,0,\"value\"],[12],[1,[30,3]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[3,4]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[\"@closeModal\",\"@model\",\"value\",\"key\"],false,[\"each\",\"-each-in\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-fingerprint/discourse/components/modal/fingerprint-details.js",
    "scope": () => [_dModal.default, _i18n.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "fingerprint-details:FingerprintDetails"));
  var _default = _exports.default = FingerprintDetails;
});